import type { ItemModel, RecordSourceFieldType, RecordSourceOptions } from 'o365-dataobject';
import { DataObject } from 'o365-dataobject';
import { serverAggregatePolyfill } from './ServerAggregatePolyfill.ts';

declare module 'o365-dataobject' {
    interface DataObject<T> {
        summaryData: SummaryData<T>;
        hasSummaryData: boolean;
    }
}

Object.defineProperties(DataObject.prototype, {
    'summaryData': {
        get() {
            if (this._summaryData == null) {
                this._summaryData = new SummaryData(this);
                this._summaryData.initialize();
            }
            return this._summaryData;
        }
    },
    'hasSummaryData': {
        get() {
            return !!this._summaryData;
        }
    }
});

/** DataObject extension for getting aggregated data */
export default class SummaryData<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _initialized = false;
    private _aggregates: SummaryAggregate<T>[] = [];
    private _summary: Partial<T> = {};

    private _cancelDataLoaded?: () => void;
    private _cancelAfterSave?: () => void;
    private _previousRequestKey?: string;
    private _isLoading = false;

    /** When true will skip reload checks based on request keys */
    skipLoadChecks = false;

    /** Summary row values */
    get summary() { return this._summary; }
    /** Aggregates for the summary row */
    get aggregates() { return this._aggregates; }

    get isLoading() { return this._isLoading; }

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
    }

    /** Initialize extension. Called by extension getter, should not be called by developer */
    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;

        this._cancelDataLoaded = this._dataObject.on('DataLoaded', (_pData, pOptions) => this.fetchAggregates.call(this, undefined, pOptions));
        this._cancelAfterSave = this._dataObject.on('AfterSave', (pOptions) => {
            if (pOptions.values && this._aggregates) {
                const fieldWithAggregateUpdated = Object.keys(pOptions.values).some(field => this._aggregates.map(x => x.name).includes(field));
                if (fieldWithAggregateUpdated || pOptions.operation == 'create' || pOptions.operation == 'update') {
                    this.fetchAggregates(true);
                }
            }
        });
        this._cancelAfterSave = this._dataObject.on('AfterDelete', (pOptions) => {
            if (this._aggregates) {
                this.fetchAggregates(true);
            }
        });
        if (this._dataObject.state.isLoaded) {
            this.fetchAggregates();
        }
    }

    disable() {
        if (this._cancelDataLoaded) {
            this._cancelDataLoaded();
        }
        if (this._cancelAfterSave) {
            this._cancelAfterSave();
        }
    }

    /** Fetch summary aggregates */
    async fetchAggregates(pSkipCheck?: boolean, pOptions?: RecordSourceOptions) {
        if (!this._dataObject.state.isLoaded) { return; }
        if (pSkipCheck == null) { pSkipCheck = this.skipLoadChecks; }
        const requestKey = this._getRequestKey(pOptions);
        if (!pSkipCheck && this._previousRequestKey === requestKey) { return; }
        if (pOptions?.skip && pOptions?.skip > 0) { return; }
        if (this._aggregates.length === 0) {
            this._summary = {};
            return;
        }
        this._isLoading = true;
        this._previousRequestKey = requestKey;
        const options = this._dataObject.recordSource.getOptions();
        if (pOptions) {
            const safeAssign = (pKey: keyof typeof options) => {
                if (pOptions !== undefined) {
                    options[pKey] = pOptions[pKey];
                }
            }
            safeAssign('whereClause');
            safeAssign('whereObject');
            safeAssign('filterString');
            safeAssign('filterObject');
            safeAssign('masterDetailObject');
            safeAssign('masterDetailString');
        }
        const customAggregates: CustomSummaryAggregate[] = [];
        const serverAggregates: ServerSummaryAggregate[] = [];
        this._aggregates.forEach(aggregate => {
            if (typeof aggregate.aggregate === 'string') {
                serverAggregates.push(aggregate);
            } else {
                customAggregates.push(aggregate);
            }
        });
        let serverPromise: Promise<Record<string, any>> | null = null;
        let customPromise: Promise<Record<string, any>> | null = null;
        if (serverAggregates.length > 0) {
            // if dynamic loading disabled
            if (!this._dataObject.hasDynamicLoading || !this._dataObject.dynamicLoading.enabled) {
                serverPromise = serverAggregatePolyfill(serverAggregates, this._dataObject.data)
            } else {
                options.fields = serverAggregates;
                options.skipAbortCheck = true;
                serverPromise = this._dataObject.recordSource.retrieve(options).then(values => {
                    return values[0] ?? {};
                });
            }
        }
        if (customAggregates.length > 0) {
            customPromise = Promise.all(customAggregates.map(aggregate => {
                return new Promise<{ name: string, value: any }>(async (res) => {
                    const value = await aggregate.aggregate(this._dataObject.storage.data);
                    res({ name: aggregate.name, value: value });
                });
            })).then(values => {
                const summaryItem: Record<string, any> = {};
                values.forEach(item => summaryItem[item.name] = item.value);
                return summaryItem;
            });
        }
        const promises: Promise<Record<string, any>>[] = [];
        if (serverPromise) { promises.push(serverPromise); }
        if (customPromise) { promises.push(customPromise); }
        const results = await Promise.all(promises);
        const result = results.reduce((result, item) => ({ ...result, ...item }), {}) as Partial<T>;

        if (result) {
            this._summary = result;
        } else {
            this._summary = {};
        }

        this._dataObject.emit('SummaryItemLoaded', this._summary);
        this._isLoading = false;
    }

    /** Set summary aggregates */
    setAggregates(pAggregates: SummaryAggregate[]) {
        if (pAggregates == null) {
            this._aggregates = [];
        } else {
            this._aggregates = pAggregates;
        }
    }

    /** Get a request key for comparing with the previous one */
    private _getRequestKey(pOptions?: RecordSourceOptions) {
        const options = this._dataObject.recordSource.getOptions();
        return JSON.stringify({
            agg: this._aggregates,
            whereClause: pOptions ? pOptions.whereClause : options.whereClause,
            filterString: pOptions ? pOptions.filterString : options.filterString,
            masterDetailString: pOptions ? pOptions.masterDetailString : options.masterDetailString,
            rowCount: this._dataObject.rowCount != null ? this._dataObject.rowCount : undefined,
            sqlStatementParameters: this._dataObject.recordSource.sqlStatementParameters,
            definitionProcParameters: this._dataObject.recordSource.definitionProcParameters,
        });
    }
}

export type AggregateFunction<T> = (pData: T[]) => Promise<any>;

export type ServerSummaryAggregate = { name: string; aggregate: NonNullable<RecordSourceFieldType['aggregate']> };
export type CustomSummaryAggregate<T = any> = { name: string; aggregate: AggregateFunction<T> };
export type SummaryAggregate<T = any> = ServerSummaryAggregate | CustomSummaryAggregate<T>;
